import Navbar from "../components/Navbar"
import { Link } from 'react-router-dom'
import { AiOutlineArrowRight } from 'react-icons/ai';

const Contact = () => {
    return (
        <>
            <header className="banner">
                <p>Contact <br /><span>Gegevens</span></p>
                <div className="overlay"></div>
                <img src="https://images.unsplash.com/photo-1615840287214-7ff58936c4cf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=774&q=80" height="170" />
            </header>

            <main id="contact">
                <section>
                    <h2>E-mail</h2>
                    <a href="mailto:jezelfmaximaliseren@gmail.com">jezelfmaximaliseren@gmail.com</a>
                </section>
                <section>
                    <h2>Telefoon-nummer</h2>
                    <a href="tel:+31628070027">+31 6 28070027</a>
                </section>
                <p>Wil jij je aanmelden voor onze inspiratie en workshop avonden?
                    <br /><br />
                    Stuur dan een Whatsapp bericht of bel naar: <a href="tel:+31628070027">+31 6 28070027</a>
                </p>
            </main>
        </>
    )
}

export default Contact