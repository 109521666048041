import Navbar from "../components/Navbar"
import review from '../assets/review.jpg'
import review1 from '../assets/review1.jpg'
import review2 from '../assets/review2.jpg'
import review3 from '../assets/review3.jpg'
import review4 from '../assets/review4.jpg'
import review5 from '../assets/review5.PNG'

const Reviews = () => {
    return (
        <>
            <header className="banner">
                <p>Onze <span>Reviews</span></p>
                <div className="overlay"></div>
                <img src="https://images.unsplash.com/photo-1633613286991-611fe299c4be?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" height="170" />
            </header>

            <main>
                <div className="reviews page">
                    <div className="reviews-images">
                        <img src={review} width="100%" />
                        <img src={review1} width="100%" />
                        <img src={review2} width="100%" />
                        <img src={review3} width="100%" />
                        <img src={review4} width="100%" />
                        <img src={review5} width="100%" />
                    </div>
                </div>
            </main>
        </>
    )
}

export default Reviews