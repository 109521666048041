import { useState } from 'react';
import Home from './screens/Home';
import Offer from './screens/Offer';
import { Route, Routes } from 'react-router-dom';
import Vision from './screens/Vision';
import Mission from './screens/Mission';
import Strategy from './screens/Strategy';
import Contact from './screens/Contact';
import Team from './screens/Team';
import Agenda from './screens/Agenda';
import Sponsors from './screens/Sponsors';
import MindsetInspiration from './pages/MindsetInspiration';
import MindsetWorkshop from './pages/MindsetWorkshop';
import EntrepreneurInspiration from './pages/EntrepreneurInspiration';
import EntrepreneurWorkshop from './pages/EntrepreneurWorkshop';
import Teambuilding from './pages/Teambuilding';
import WealthInspiration from './pages/WealthInspiration';
import WealthWorkshop from './pages/WealthWorkshop';
import VitalityInspiration from './pages/VitalityInspiration';
import Comeback from './pages/Presentation';
import WalkIn from './pages/WalkIn';
import WalkInOne from './pages/WalkInOne';
import WalkInTwo from './pages/WalkInTwo';
import WalkInThree from './pages/WalkInThree';
import VitalityWorkshop from './pages/VitalityWorkshop';
import Reviews from './screens/Reviews';

function App() {

  return (
    <Routes>
      <Route index element={<Home />}></Route>
      <Route path="agenda" element={<Agenda />}></Route>
      <Route path="mindset-inspiratie-avond" element={<MindsetInspiration />}></Route>
      <Route path="mindset-workshop-avond" element={<MindsetWorkshop />}></Route>
      <Route path="ondernemen-inspiratie-avond" element={<EntrepreneurInspiration />}></Route>
      <Route path="ondernemen-workshop-avond" element={<EntrepreneurWorkshop />}></Route>
      <Route path="teambuildingsuitje" element={<Teambuilding />}></Route>
      <Route path="vermogen-inspiratie-avond" element={<WealthInspiration />}></Route>
      <Route path="vermogen-workshop-avond" element={<WealthWorkshop />}></Route>
      <Route path="vitaliteit-inspiratie-avond" element={<VitalityInspiration />}></Route>
      <Route path="vitaliteit-workshop-avond" element={<VitalityWorkshop />}></Route>
      <Route path="inloop-avond" element={<WalkIn />}></Route>
      <Route path="inloop-avond-1" element={<WalkInOne />}></Route>
      <Route path="inloop-avond-2" element={<WalkInTwo />}></Route>
      <Route path="inloop-avond-3" element={<WalkInThree />}></Route>
      <Route path="presentatie-avond" element={<Comeback />}></Route>
      <Route path="onze-organisatie" element={<Team />}></Route>
      <Route path="sponsors-en-vrienden" element={<Sponsors />}></Route>
      <Route path="reviews" element={<Reviews />}></Route>
      <Route path="contact-gegevens" element={<Contact />}></Route>
    </Routes>
  );
}

export default App;
