import { useState } from 'react';
import { AiOutlineArrowDown, AiOutlineArrowRight } from 'react-icons/ai';
import Navbar from '../components/Navbar';
import { Link } from 'react-router-dom'
import video from '../assets/video.MP4';
import palm from '../assets/palm.png'
import pointing from '../assets/pointing.png'
import bicep from '../assets/bicep.png'
import bulb from '../assets/bulb.png'
import smilesunglasses from '../assets/smilesunglasses.png'
import workshop1 from '../assets/workshop1.JPG'
import inspiratie1 from '../assets/inspiratie1.JPG'
import inspiratie2 from '../assets/inspiratie2.JPG'
import workshop2 from '../assets/workshop2.JPG'
import review from '../assets/review.jpg'
import review1 from '../assets/review1.jpg'
import review2 from '../assets/review2.jpg'
import review3 from '../assets/review3.jpg'
import review4 from '../assets/review4.jpg'
import review5 from '../assets/review5.PNG'

const Home = () => {
    const handleLinkClick = () => {
        window.scrollTo(0, 0);
    };

    return (
        <>
            <div id="home-header">
                <header id="home-banner">
                    <div id="home-banner-overlay">
                        <div id="home-banner-text">
                            <p>Maximaliseer jouw <span>mentale</span>, <span>financiële</span> en <span>fysieke</span> gezondheid</p>
                            <Link to="/agenda" id="footer-button" onClick={handleLinkClick}> Bekijk Onze Agenda <AiOutlineArrowRight id="agenda-arrow" /></Link>
                        </div>
                        <div>
                            <AiOutlineArrowDown />
                        </div>
                    </div>
                    <video autoPlay muted loop playsInline id="myVideo">
                        <source src={video} type="video/mp4" />
                        Your browser does not support HTML5 video.
                    </video>
                </header>
            </div>
            <main>
                <div className="sfeer">
                    <div>
                        <img src={workshop1} height="100px" width="100%" id="workshop" />
                        <p>Wil jij graag meer leren over het hebben en creëeren van een positieve mindset...</p>
                    </div>
                    <div>
                        <img src={inspiratie1} height="100px" width="100%" />
                        <p>hoe je een eigen onderneming start, hoe je jezelf verkoopt aan een opdrachtgever...</p>
                    </div>
                    <div>
                        <img src={workshop2} height="100px" width="100%" />
                        <p>hoe je een groot vermogen opbouwt en hoe je het maximale uit jouw dag haalt door middel van sport, voeding, structuur, slaap en nog veel meer?</p>
                    </div>
                    <div>
                        <img src={inspiratie2} height="100px" width="100%" id="inspiratie2" />
                        <p>Bekijk dan onze agenda en meld je aan door te Whatsappen of te bellen naar: </p>
                        <p><b><a href="tel:+31628070027" id="phoneNumber">+31 6 28070027</a></b></p>
                    </div>
                </div>
                <Link to="/agenda" id="footer-button" onClick={handleLinkClick}> Bekijk Onze Agenda <AiOutlineArrowRight /></Link>
                <div className="usps">
                    <section>
                        <img src={palm} width="48" />
                        <h2>Leef jouw droomleven!</h2>
                        <p>Het is onze missie om jou <b>gratis</b>
                            <br />
                            zoveel mogelijk dichterbij jouw
                            <br />
                            doel te krijgen.</p>
                    </section>
                    <section>
                        <img src={pointing} width="48" />
                        <h2>Voor Wie</h2>
                        <p>Wij focussen ons op de doelgroep
                            jongeren tussen de leeftijd van
                            <br />
                            16 tot en met 27 jaar.</p>
                    </section>
                    <section>
                        <img src={bicep} width="48" />
                        <h2>Waarom</h2>
                        <p>Wij willen jou in je kracht zetten, zodat je het meeste uit je leven kan halen op mentaal, financieel en fysiek vlak.</p>
                    </section>
                    <section>
                        <img src={bulb} width="48" />
                        <h2>Hoe</h2>
                        <p>Door jou te inspireren
                            <br />
                            op gebied van mentale, financiële
                            <br />
                            en fysieke gezondheid.</p>
                    </section>
                    <section>
                        <img src={smilesunglasses} width="48" />
                        <h2>Wat</h2>
                        <p>Inspiratie-/workshopavonden
                            <br />
                            organiseren voor jou omtrent
                            <br />
                            mindset, geld en vitaliteit. </p>
                    </section>
                </div>
                <Link to="/agenda" id="footer-button" onClick={handleLinkClick}> Bekijk Onze Agenda <AiOutlineArrowRight /></Link>
                <div className="reviews">
                    <h2>Reviews</h2>
                    <div className="reviews-images">
                        <img src={review} width="100%" />
                        <img src={review1} width="100%" />
                        <img src={review2} width="100%" />
                        <img src={review3} width="100%" />
                        <img src={review4} width="100%" />
                        <img src={review5} width="100%" />
                    </div>
                </div>
                <Link to="/contact-gegevens" id="footer-button" onClick={handleLinkClick}> Contact Opnemen <AiOutlineArrowRight /></Link>
            </main>
        </>
    )
}

export default Home