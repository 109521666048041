import logo from '../assets/logo.png';
import { AiOutlineMenu, AiFillEye, AiFillContacts, AiOutlineClose, AiFillHome } from 'react-icons/ai';
import { Link, NavLink } from 'react-router-dom'
import { BsPeopleFill } from 'react-icons/bs';
import { FaCalendarAlt } from 'react-icons/fa';
import { FaHandshake } from 'react-icons/fa';
import { MdReviews } from 'react-icons/md';
import { useState } from 'react';

const Navbar = () => {
    const [toggle, setToggle] = useState(false)

    const handleLinkClick = () => {
        window.scrollTo(0, 0);
        setToggle(false)
    };

    return (
        <nav>
            <Link to="/"><img src={logo} alt="logo" height='64' /></Link>
            {toggle ? <AiOutlineClose onClick={() => setToggle(!toggle)} /> : <AiOutlineMenu id="menu" onClick={() => setToggle(!toggle)} />}
            {toggle &&
                <ul>
                    <NavLink to="/" onClick={handleLinkClick} activeClassName="active"><AiFillHome />Homepagina</NavLink>
                    <NavLink to="/agenda" onClick={handleLinkClick} activeClassName="active"><FaCalendarAlt />Onze Agenda</NavLink>
                    <NavLink to="/onze-organisatie" onClick={handleLinkClick} activeClassName="active"><BsPeopleFill />Onze Organisatie</NavLink>
                    <NavLink to="/sponsors-en-vrienden" onClick={handleLinkClick} activeClassName="active"><FaHandshake />Sponsors en Vrienden</NavLink>
                    <NavLink to="/reviews" onClick={handleLinkClick} activeClassName="active"><MdReviews />Reviews</NavLink>
                    <NavLink to="/contact-gegevens" onClick={handleLinkClick} activeClassName="active"><AiFillContacts />Contact Gegevens</NavLink>
                </ul>
            }
            <ul id="desktop-nav">
                <NavLink to="/" onClick={handleLinkClick} activeClassName="active"><AiFillHome />Homepagina</NavLink>
                <NavLink to="/agenda" onClick={handleLinkClick} activeClassName="active"><FaCalendarAlt />Onze Agenda</NavLink>
                <NavLink to="/onze-organisatie" onClick={handleLinkClick} activeClassName="active"><BsPeopleFill />Onze Organisatie</NavLink>
                <NavLink to="/sponsors-en-vrienden" onClick={handleLinkClick} activeClassName="active"><FaHandshake />Sponsors en Vrienden</NavLink>
                <NavLink to="/reviews" onClick={handleLinkClick} activeClassName="active"><MdReviews />Reviews</NavLink>
                <NavLink to="/contact-gegevens" onClick={handleLinkClick} activeClassName="active"><AiFillContacts />Contact Gegevens</NavLink>
            </ul>
        </nav>
    )
}

export default Navbar