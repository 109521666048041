import { Link } from 'react-router-dom'

const VitalityInspiration = () => {

    return (
        <>
            <header className="banner">
                <p>Vitaliteit <span>Inspiratie Avond</span></p>
                <div className="overlay"></div>
                <img src="https://images.unsplash.com/photo-1491609154219-ffd3ffafd992?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8dml0YWxpdHl8ZW58MHx8MHx8fDA%3D" height="170" />
            </header>

            <main className="event content">
                <div className="event-contact">
                    <div className="event-details">
                        <b>1 maart 2024</b>
                        <p>19:00 - 23:00</p>
                    </div>
                    <Link to="/contact-gegevens">Aanmelden</Link>
                </div>

                <hr />

                <p>Je zal leren over hoe je het meeste energie uit een dag en dus ook uit jouw leven haalt.
                    <br /><br />
                    We gaan het bijvoorbeeld hebben over sport, voeding, structuur, slaap en nog vele andere onderwerpen die invloed hebben op jouw dagelijkse vitaliteit (energie).
                    <br /><br />
                    <p><span>Locatie: </span>Winkelcentrum Cityplaza Nieuwegein, Raadstede 80, 3431 HA Nieuwegein</p>
                    <p><span>Datum: </span>01-03-2024</p>
                    <p><span>Dag: </span>Vrijdag</p>
                    <p><span>Tijd: </span>19:00 - 23:00</p>
                    <p><span>Kosten: </span>Gratis</p>
                </p>
                <br />
                <p>Onze locatie zit in de rij van ANWB winkel tegenover het gemeentehuis op de hoek.</p>

                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9819.36549958296!2d5.084504001457509!3d52.0279873819372!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c6645ab1c59dcb%3A0x25d47a0a6da4d6b!2sRaadstede%2080%2C%203431%20HA%20Nieuwegein!5e0!3m2!1sen!2snl!4v1703000768206!5m2!1sen!2snl" style={{ border: 'none', borderRadius: 5, marginTop: 20 }} width="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </main>
        </>
    )
}

export default VitalityInspiration