import { Link } from 'react-router-dom'

const EntrepreneurInspiration = () => {

    return (
        <>
            <header className="banner">
                <p>Ondernemen <span>Inspiratie Avond</span></p>
                <div className="overlay"></div>
                <img src="https://images.unsplash.com/photo-1507679799987-c73779587ccf?q=80&w=2942&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" height="170" />
            </header>

            <main className="event content">
                <div className="event-contact">
                    <div className="event-details">
                        <b>26 januari 2024</b>
                        <p>19:00 - 23:00</p>
                    </div>
                    <Link to="/contact-gegevens">Aanmelden</Link>
                </div>

                <hr />

                <p>Je zal van alles leren over een eigen onderneming starten.
                    <br /><br />
                    <ul>
                        <li>
                            Hoe je een goed business plan maakt
                        </li>
                        <li>
                            Hoe je de juiste doelgroep bereikt
                        </li>
                        <li>
                            Hoe je de juiste content maakt om jouw doelgroep te bereiken
                        </li>
                        <li>
                            Hoe je potentiële klanten tot betalende klanten maakt.
                        </li>
                        <li>
                            Hoe je de juiste klantenservice aan jouw klanten biedt.
                        </li>
                    </ul>
                    <br />
                    Het kan zijn dat je geen eigen onderneming wilt, dan nog bevelen wij het aan om hier deel aan te nemen, omdat wij geloven dat deze vraagstukken jou wel dichter bij jouw roeping (doel) krijgt.
                    <br /><br />
                    <p><span>Locatie: </span>Winkelcentrum Cityplaza Nieuwegein, Raadstede 80, 3431 HA Nieuwegein</p>
                    <p><span>Datum: </span>26-01-2024</p>
                    <p><span>Dag: </span>Vrijdag</p>
                    <p><span>Tijd: </span>19:00 - 23:00</p>
                    <p><span>Kosten: </span>Gratis</p>
                </p>

                <br />
                <p>Onze locatie zit in de rij van ANWB winkel tegenover het gemeentehuis op de hoek.</p>

                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9819.36549958296!2d5.084504001457509!3d52.0279873819372!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c6645ab1c59dcb%3A0x25d47a0a6da4d6b!2sRaadstede%2080%2C%203431%20HA%20Nieuwegein!5e0!3m2!1sen!2snl!4v1703000768206!5m2!1sen!2snl" style={{ border: 'none', borderRadius: 5, marginTop: 20 }} width="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </main>
        </>
    )
}

export default EntrepreneurInspiration