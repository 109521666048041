import Navbar from "../components/Navbar"
import { AiFillInstagram, AiFillLinkedin } from 'react-icons/ai';
import alex from '../assets/alex.JPG'
import peter from '../assets/peter.JPG'
import roelien from '../assets/roelien.jpeg'
import jeroen from '../assets/jeroen.jpeg'
import mission from '../assets/mission.png'
import vision from '../assets/vision.png'
import hearthands from '../assets/hearthands.png'
import handshake from '../assets/handshake.png'
import hug from '../assets/hug.png'
import vsbfonds from '../assets/vsbfonds.png'
import geinsgeluk from '../assets/geinsgeluk.png'
import movactor from '../assets/movactor.png'
import ruilfabriek from '../assets/ruilfabriek.png'
import strategy from '../assets/strategy.png'
import { Link } from 'react-router-dom'
import { AiOutlineArrowRight } from 'react-icons/ai';

const Team = () => {
    return (
        <>
            <header className="banner">
                <p>Sponsors en <br /><span>Vrienden</span></p>
                <div className="overlay"></div>
                <img src="https://images.unsplash.com/photo-1462206092226-f46025ffe607?q=80&w=2299&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" height="170" />
            </header>

            <main className="content team">
                <section className="logo-item">
                    <h2>Sponsors</h2>
                    <p>Wij zijn dankbaar dat deze sponsors ons project mogelijk maken.</p>
                    <a href="https://www.vsbfonds.nl/">
                        <img src={vsbfonds} width="192" />
                    </a>
                    <a href="https://geinsgeluk.nl/">
                        <img src={geinsgeluk} width="128" />
                    </a>
                </section>
                <section className="logo-item">
                    <h2>Vrienden</h2>
                    <p>Samen met deze partijen maken wij onze visie realiteit.</p>
                    <a href="https://movactor.nl/">
                        <img src={movactor} height="64" />
                    </a>
                    <a href="https://www.deruilfabriek.nl/">
                        <img src={ruilfabriek} height="64" />
                    </a>
                </section>
            </main>
        </>
    )
}

export default Team