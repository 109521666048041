import { FaInstagram } from 'react-icons/fa';


const Footer = () => {
    return (
        <div className="footer">
            <FaInstagram />
            <p>Gemaakt door <a href="https://www.instagram.com/eenjesta">Alex Eenjes</a></p>
        </div>
    )
}

export default Footer