import { Link } from 'react-router-dom'

const Agenda = () => {

    return (
        <>
            <header className="banner">
                <img src="https://images.unsplash.com/photo-1435527173128-983b87201f4d?q=80&w=2934&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" height="170" />
                <div className="overlay"></div>
                <p>Onze <span>Agenda</span></p>
            </header>

            <main className="agenda content">
                <div className="agenda-point">
                    <div className="agenda-point-image">
                        <span className="date">12 januari 2024</span>
                        <img src="https://images.unsplash.com/photo-1507413245164-6160d8298b31?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="brain" width="128" />
                    </div>
                    <div className="agenda-point-details">
                        <b>Mindset Inspiratie Avond</b>
                        <p>Creëer een sterke mindset</p>
                        <Link to="/mindset-inspiratie-avond">Meer Informatie</Link>
                    </div>
                </div>
                <hr />
                <div className="agenda-point">
                    <div className="agenda-point-image">
                        <span className="date">19 januari 2024</span>
                        <img src="https://images.unsplash.com/photo-1490730141103-6cac27aaab94?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="brain" width="128" />
                    </div>
                    <div className="agenda-point-details">
                        <b>Mindset Workshop Avond</b>
                        <p>Ga aan de slag met jezelf</p>
                        <Link to="/mindset-workshop-avond">Meer Informatie</Link>
                    </div>
                </div>
                <hr />
                <div className="agenda-point">
                    <div className="agenda-point-image">
                        <span className="date">26 januari 2024</span>
                        <img src="https://images.unsplash.com/photo-1507679799987-c73779587ccf?q=80&w=2942&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="brain" width="128" />
                    </div>
                    <div className="agenda-point-details">
                        <b>Ondernemen Inspiratie Avond</b>
                        <p>Leer over ondernemen</p>
                        <Link to="/ondernemen-inspiratie-avond">Meer Informatie</Link>
                    </div>
                </div>
                <hr />
                <div className="agenda-point">
                    <div className="agenda-point-image">
                        <span className="date">2 februari 2024</span>
                        <img src="https://images.unsplash.com/photo-1520607162513-77705c0f0d4a?q=80&w=2938&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="brain" width="128" />
                    </div>
                    <div className="agenda-point-details">
                        <b>Ondernemen Workshop Avond</b>
                        <p>Creëer je eigen business</p>
                        <Link to="/ondernemen-workshop-avond">Meer Informatie</Link>
                    </div>
                </div>
                <hr />
                <div className="agenda-point">
                    <div className="agenda-point-image">
                        <span className="date">9 februari 2024</span>
                        <img src="https://images.unsplash.com/photo-1596021688656-35fdc9ed0274?q=80&w=2843&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="brain" width="128" />
                    </div>
                    <div className="agenda-point-details">
                        <b>Teambuildingsuitje</b>
                        <p>Kom jezelf en anderen tegen</p>
                        <Link to="/teambuildingsuitje">Meer Informatie</Link>
                    </div>
                </div>
                <hr />
                <div className="agenda-point">
                    <div className="agenda-point-image">
                        <span className="date">16 februari 2024</span>
                        <img src="https://images.unsplash.com/photo-1593672715438-d88a70629abe?q=80&w=2787&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="brain" width="128" />
                    </div>
                    <div className="agenda-point-details">
                        <b>Vermogen Inspiratie Avond</b>
                        <p>Leer over vermogen opbouwen</p>
                        <Link to="/vermogen-inspiratie-avond">Meer Informatie</Link>
                    </div>
                </div>
                <hr />
                <div className="agenda-point">
                    <div className="agenda-point-image">
                        <span className="date">23 februari 2024</span>
                        <img src="https://images.unsplash.com/photo-1553729459-efe14ef6055d?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="brain" width="128" />
                    </div>
                    <div className="agenda-point-details">
                        <b>Vermogen Workshop Avond</b>
                        <p>Krijg inzage in je financiën</p>
                        <Link to="/vermogen-workshop-avond">Meer Informatie</Link>
                    </div>
                </div>
                <hr />
                <div className="agenda-point">
                    <div className="agenda-point-image">
                        <span className="date">1 maart 2024</span>
                        <img src="https://images.unsplash.com/photo-1491609154219-ffd3ffafd992?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8dml0YWxpdHl8ZW58MHx8MHx8fDA%3D" alt="brain" width="128" />
                    </div>
                    <div className="agenda-point-details">
                        <b>Vitaliteit Inspiratie Avond</b>
                        <p>Zo wordt je energieker</p>
                        <Link to="/vitaliteit-inspiratie-avond">Meer Informatie</Link>
                    </div>
                </div>
                <hr />
                <div className="agenda-point">
                    <div className="agenda-point-image">
                        <span className="date">8 maart 2024</span>
                        <img src="https://images.unsplash.com/photo-1507835661088-ac1e84fe645f?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8dml0YWxpdHl8ZW58MHx8MHx8fDA%3D" alt="brain" width="128" />
                    </div>
                    <div className="agenda-point-details">
                        <b>Vitaliteit Workshop Avond</b>
                        <p>Een avond sparren met elkaar</p>
                        <Link to="/vitaliteit-workshop-avond">Meer Informatie</Link>
                    </div>
                </div>
                <hr />
                <div className="agenda-point">
                    <div className="agenda-point-image">
                        <span className="date">29 maart 2024</span>
                        <img src="https://images.unsplash.com/photo-1578574577315-3fbeb0cecdc2?q=80&w=2944&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="brain" width="128" />
                    </div>
                    <div className="agenda-point-details">
                        <b>Inloopavond</b>
                        <p>Kom met al je vragen</p>
                        <Link to="/inloop-avond">Meer Informatie</Link>
                    </div>
                </div>
                <hr />
                <div className="agenda-point">
                    <div className="agenda-point-image">
                        <span className="date">12 april 2024</span>
                        <img src="https://images.unsplash.com/photo-1591115765373-5207764f72e7?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="brain" width="128" />
                    </div>
                    <div className="agenda-point-details">
                        <b>Presentatie Avond</b>
                        <p>Presenteer jouw ontwikkeling</p>
                        <Link to="/presentatie-avond">Meer Informatie</Link>
                    </div>
                </div>
                <hr />
                <div className="agenda-point">
                    <div className="agenda-point-image">
                        <span className="date">26 april 2024</span>
                        <img src="https://images.unsplash.com/photo-1507537362848-9c7e70b7b5c1?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="brain" width="128" />
                    </div>
                    <div className="agenda-point-details">
                        <b>Inloopavond</b>
                        <p>Kom met al je vragen</p>
                        <Link to="/inloop-avond-1">Meer Informatie</Link>
                    </div>
                </div>
                <hr />
                <div className="agenda-point">
                    <div className="agenda-point-image">
                        <span className="date">10 mei 2024</span>
                        <img src="https://images.unsplash.com/photo-1551836022-d5d88e9218df?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="brain" width="128" />
                    </div>
                    <div className="agenda-point-details">
                        <b>Inloopavond</b>
                        <p>Kom met al je vragen</p>
                        <Link to="/inloop-avond-2">Meer Informatie</Link>
                    </div>
                </div>
                <hr />
                <div className="agenda-point">
                    <div className="agenda-point-image">
                        <span className="date">24 mei 2024</span>
                        <img src="https://images.unsplash.com/photo-1573497491208-6b1acb260507?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="brain" width="128" />
                    </div>
                    <div className="agenda-point-details">
                        <b>Inloopavond</b>
                        <p>Kom met al je vragen</p>
                        <Link to="/inloop-avond-3">Meer Informatie</Link>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Agenda