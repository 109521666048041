import Navbar from "../components/Navbar"
import { AiFillInstagram, AiFillLinkedin } from 'react-icons/ai';
import alex from '../assets/alex.JPG'
import peter from '../assets/peter.JPG'
import roelien from '../assets/roelien.jpeg'
import jeroen from '../assets/jeroen.jpeg'
import mission from '../assets/mission.png'
import vision from '../assets/vision.png'
import strategy from '../assets/strategy.png'
import { Link } from 'react-router-dom'
import { AiOutlineArrowRight } from 'react-icons/ai';

const Team = () => {
    return (
        <>
            <header className="banner">
                <p>Onze <br /><span>Organisatie</span></p>
                <div className="overlay"></div>
                <img src="https://images.unsplash.com/photo-1600880292089-90a7e086ee0c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=774&q=80" height="170" />
            </header>

            <main className="content team">
                <div id="team">
                    <div id="member">
                        <img src={alex} />
                        <h3>Alex Eenjes</h3>
                        <p>Visionair / Coach</p>
                        <div id="socials">
                            <a href="https://www.instagram.com/eenjesta/"><AiFillInstagram /></a>
                            <a href="https://www.linkedin.com/in/eenjesta/"><AiFillLinkedin /></a>
                        </div>
                    </div>
                    <div id="member">
                        <img src={peter} />
                        <h3>Peter Geurtsen</h3>
                        <p>Voorzitter</p>
                        <div id="socials">
                            <a href="https://www.linkedin.com/in/geurtsenpeter/"><AiFillLinkedin /></a>
                        </div>
                    </div>
                    <div id="member">
                        <img src={roelien} />
                        <h3>Roelien Zantvoort</h3>
                        <p>Secretariaat</p>
                    </div>
                    <div id="member">
                        <img src={jeroen} />
                        <h3>Jeroen Hendriks</h3>
                        <p>Financiën</p>
                    </div>
                </div>

                <section>
                    <img src={mission} width="48" alt="bullseye" />
                    <h2>Missie</h2>
                    <p>Ons doel is om een locatie te creëeren waar jij <b>gratis</b> geïnspireerd kan worden om dichter bij jouw doel te komen.
                        <br /><br />
                        Zodat jij jouw talenten kan ontdekken, verder kan ontwikkelen en kan benutten.
                        <br /><br />
                        Op het moment dat jij jouw purpose (doel) scherp hebt, dan heb je minder de neiging om verkeerde keuzes te maken.
                    </p>
                </section>

                <section>
                    <img src={vision} width="48" alt="flag" />
                    <h2>Visie</h2>
                    <p>Omdat jij vanuit je purpose bewustere keuzes maakt, zal dit vaker leiden tot positieve uitkomsten wat er voor zal zorgen dat jouw motivatie, discipline, en je geluksniveau toeneemt.
                        <br /><br />
                        We kijken samen met jou waar je nu staat (punt A) en bedenken samen de weg waar jij naartoe wilt (punt B).
                        <br /><br />
                        Daarom organiseren wij voor jou regelmatig inspiratie- en workshopavonden over mindset, geld en vitaliteit.
                        <br /><br />
                        Waar nodig kunnen we jou ook met de juiste partijen in verbinding brengen om jouw ontwikkeling te realiseren.</p>
                </section>

                <section>
                    <img src={strategy} width="48" alt="brain" />
                    <h2>Strategie</h2>
                    <p>Wij organiseren op onze locatie inspiratie- en workshopavonden omtrent mindset, geld en vitaliteit.
                        <br /><br />
                        Naast dat je geïnspireerd kan worden en er de mogelijkheid is om de inspiratie te praktiseren tijdens de workshopavonden.
                        <br /><br />
                        Is er ook de mogelijkheid om te netwerken met rolmodellen en leeftijdsgenoten.
                        <br /><br />
                        Als laatst organiseren wij ook aanvullende evenementen waarbij je jezelf kan ontwikkelen en plezier kan hebben (denk aan sporten, muziek maken, koken, culturele evenementen, locaties waar mensen je talenten willen benutten en nog veel meer).</p>
                </section>
                <Link to="/contact-gegevens" id="footer-button">Contact Opnemen <AiOutlineArrowRight /></Link>
            </main>
        </>
    )
}

export default Team